import { graphql } from "gatsby";
import {
  LeadSection,
  Post,
  CardGrid,
  PostContentsTitle,
  Title,
  PostContentsContainer,
  Button,
} from "@lleed-partners/factor";
import { Icon } from "../fragments/Icon";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "../fragments/Card/fragments.tsx";
import {
  Link as GatsbyLink,
  useI18next,
  Helmet,
} from "gatsby-plugin-react-i18next";
import { ArrowRight32 } from "@carbon/icons-react";
import { mapElasticSearchResults } from "../graphql/helpers";
import { Layout } from ".";
import { InsightCard } from "../fragments/Card/insight";
import { Header } from "../fragments/Header";
import { ServiceCard } from "../fragments/Card/service";
import { getSrc } from "gatsby-plugin-image";

const ServicesLayout = ({
  data: {
    post: { frontmatter, body },
    subServices,
    relatedInsights: rawRelatedInsights,
  },
}) => {
  const { t } = useI18next();

  const relatedInsights = mapElasticSearchResults(rawRelatedInsights);

  return (
    <Layout navProps={{ showSubNav: false }}>
      <article>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Service",
              name: frontmatter.title,
              description: frontmatter.description,
              image: (() => {
                if (frontmatter.localHeaderImage) {
                  return [getSrc(frontmatter.localHeaderImage)];
                } else if (frontmatter.headerImage) {
                  return [getSrc(frontmatter.headerImage)];
                } else {
                  return [];
                }
              })(),
              provider: {
                "@type": "Organization",
                name: "Lleed & Partners",
                url: "https://lleedpartners.com/",
              },
            })}
          </script>
        </Helmet>
        <Header frontmatter={frontmatter} />
        <Post>
          <MDXRenderer
            localImages={frontmatter.localImages}
            remoteImages={frontmatter.remoteImages}
          >
            {body}
          </MDXRenderer>
          {subServices.edges.length > 0 ? (
            <>
              <PostContentsTitle title={<Title title={frontmatter.title} />} />
              <PostContentsContainer position="center-full">
                <CardGrid intent="white" bordered>
                  {subServices.edges.map(({ node }) => (
                    <ServiceCard service={node} />
                  ))}
                </CardGrid>
              </PostContentsContainer>
            </>
          ) : null}
          <PostContentsContainer position="center-right-full">
            <LeadSection
              title={frontmatter.title}
              inline
              intent="grey"
              subtitle={frontmatter.subtitle}
              cta={
                <Button
                  as={GatsbyLink}
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                  to="/contact/"
                >
                  {t("Contact us")}
                </Button>
              }
            />
          </PostContentsContainer>
          {relatedInsights.edges.length ? (
            <>
              <PostContentsTitle
                title={
                  <Title title={t("Insights")}>
                    {t("Discover our latest insights")}
                  </Title>
                }
              />
              <PostContentsContainer position="center-full">
                <CardGrid intent="white" bordered>
                  {relatedInsights.edges.slice(0, 3).map(({ node }) => (
                    <InsightCard insight={node} />
                  ))}
                </CardGrid>
              </PostContentsContainer>
            </>
          ) : null}
        </Post>
      </article>
    </Layout>
  );
};

export default ServicesLayout;

export const pageQuery = graphql`
  query (
    $id: String!
    $slug: String!
    $language: String!
    $suggestedQuery: JSON!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    post: mdx(id: { eq: $id }) {
      body
      frontmatter {
        category
        created
        modified
        description
        headerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localHeaderImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        remoteImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        icon
        service
        slug
        subtitle
        title
      }
    }

    subServices: allMdx(
      filter: {
        frontmatter: { category: { eq: "service" }, service: { eq: $slug } }
        fields: { langKey: { eq: $language } }
      }
    ) {
      edges {
        node {
          ...CardServiceFragment
        }
      }
    }

    relatedInsights: elastic {
      search(body: $suggestedQuery)
    }
  }
`;
